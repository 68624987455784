import React from 'react';

export default function Terms() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Terms of Business</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Last updated: December 2024
          </p>
        </div>
        
        <div className="mx-auto mt-16 max-w-2xl space-y-8 text-base leading-7 text-gray-300">
          <section>
            <h3 className="text-xl font-semibold text-white mb-4">1. Definitions</h3>
            <p className="mb-4">
              "Agreement" means these Terms of Business, together with any Proposal or Statement of Work.
              "Client" means the person or entity who purchases Services from Coupled Logic.
              "Services" means the software development, consulting, and related services provided by Coupled Logic.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">2. Services</h3>
            <p className="mb-4">
              Coupled Logic will provide the Services with reasonable skill and care, consistent with industry standards.
              The specific details, deliverables, and timelines of the Services will be outlined in a separate Proposal
              or Statement of Work.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">3. Client Responsibilities</h3>
            <p className="mb-4">
              The Client shall provide Coupled Logic with all necessary information, access, and cooperation required
              to deliver the Services. This includes timely feedback, approvals, and any required access to systems or resources.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">4. Payments</h3>
            <p className="mb-4">
              Fees for Services will be outlined in the Proposal. Unless otherwise agreed:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Payment terms are 30 days from invoice date</li>
              <li>All fees are exclusive of VAT</li>
              <li>Coupled Logic reserves the right to charge interest on overdue amounts</li>
            </ul>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">5. Intellectual Property</h3>
            <p className="mb-4">
              Upon full payment, the Client will own all rights to the deliverables specifically created for the Client.
              Coupled Logic retains ownership of any pre-existing materials, tools, or methodologies used in creating
              the deliverables.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">6. Confidentiality</h3>
            <p className="mb-4">
              Both parties agree to keep confidential any proprietary or sensitive information disclosed during the
              provision of Services. This obligation survives the termination of the Agreement.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">7. Limitation of Liability</h3>
            <p className="mb-4">
              Coupled Logic's total liability under this Agreement shall be limited to the fees paid for the Services.
              Neither party shall be liable for any indirect, consequential, or special damages.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">8. Termination</h3>
            <p className="mb-4">
              Either party may terminate this Agreement with 30 days written notice. Upon termination, the Client shall
              pay for all Services provided up to the termination date.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">9. Governing Law</h3>
            <p className="mb-4">
              This Agreement is governed by the laws of England and Wales. Any disputes shall be subject to the exclusive
              jurisdiction of the courts of England and Wales.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">10. Changes to Terms</h3>
            <p className="mb-4">
              Coupled Logic reserves the right to modify these terms at any time. Changes will be effective upon posting
              to our website. Continued use of our Services constitutes acceptance of the modified terms.
            </p>
          </section>

          <div className="mt-12 text-sm text-gray-400">
            <p>
              For any questions about these Terms of Business, please contact us at innovation@coupledlogic.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}