import React from 'react';

export default function Privacy() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Privacy Policy</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Last updated: December 2024
          </p>
        </div>
        
        <div className="mx-auto mt-16 max-w-2xl space-y-8 text-base leading-7 text-gray-300">
          <section>
            <h3 className="text-xl font-semibold text-white mb-4">Introduction</h3>
            <p className="mb-4">
              Coupled Logic ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains
              how we collect, use, and protect your personal information when you use our website and services.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">Information We Collect</h3>
            <p className="mb-4">We collect the following types of information:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Contact information (name, email, phone number)</li>
              <li>Business information (company name, role)</li>
              <li>Website usage data (through cookies and analytics)</li>
              <li>Project requirements and specifications</li>
              <li>Communication records</li>
            </ul>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">How We Use Your Information</h3>
            <p className="mb-4">We use your information to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Provide and improve our services</li>
              <li>Communicate with you about your projects</li>
              <li>Send you important updates and notifications</li>
              <li>Process payments</li>
              <li>Comply with legal obligations</li>
              <li>Analyze and improve our website performance</li>
            </ul>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">Data Protection</h3>
            <p className="mb-4">
              We implement appropriate technical and organizational measures to protect your personal information
              against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission
              over the internet is 100% secure.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">Cookies</h3>
            <p className="mb-4">
              We use cookies to improve your browsing experience and analyze website traffic. You can control cookie
              settings through your browser preferences. By continuing to use our website, you consent to our use
              of cookies.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">Third-Party Services</h3>
            <p className="mb-4">
              We may use third-party services for analytics, payment processing, and other business functions.
              These services have their own privacy policies and may collect information according to their own practices.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">Your Rights</h3>
            <p className="mb-4">You have the right to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Access your personal information</li>
              <li>Correct inaccurate information</li>
              <li>Request deletion of your information</li>
              <li>Object to processing of your information</li>
              <li>Withdraw consent for specific processing activities</li>
              <li>Request a copy of your information</li>
            </ul>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">Data Retention</h3>
            <p className="mb-4">
              We retain your personal information for as long as necessary to provide our services and comply with
              legal obligations. When data is no longer needed, it is securely deleted or anonymized.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">Changes to This Policy</h3>
            <p className="mb-4">
              We may update this Privacy Policy from time to time. Changes will be posted on this page with an
              updated revision date. Continued use of our services after changes constitutes acceptance of the
              updated policy.
            </p>
          </section>

          <section>
            <h3 className="text-xl font-semibold text-white mb-4">Contact Us</h3>
            <p className="mb-4">
              If you have questions about this Privacy Policy or wish to exercise your rights, please contact us at:
            </p>
            <p className="text-white">
              Email: innovation@coupledlogic.com<br />
              Address: 7 Bell Yard, London, WC2A 2JR<br />
              Phone: +44 203 576 3320
            </p>
          </section>

          <div className="mt-12 text-sm text-gray-400">
            <p>
              This Privacy Policy is compliant with the UK General Data Protection Regulation (UK GDPR) and the
              Data Protection Act 2018.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}